import React from "react"

import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function InformationPage({ data }) {
  return  (
    <Layout>
      <SEO 
        pagetitle="インフォメーション"
      />
      <div className="keni-main_wrap">
        <div className="keni-main_outer">
          <main id="main" className="keni-main">
            <div className="keni-main_inner">
              <div className="keni-section_wrap">
                <h1 className="section-title">インフォメーション</h1>
                <section className="keni-section">
                  <h2>お知らせ</h2>
                    {data.allMarkdownRemark.edges.map(({ node }) => (
                        <div className="contents">
                            <h4>{node.frontmatter.title}</h4>
                            <div className="news-date">{node.frontmatter.date}</div>
                            <div dangerouslySetInnerHTML={{ __html: node.html }} />
                        </div>
                    ))}
                </section>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
    query InformationQuery {
        allMarkdownRemark(
            sort: {fields: frontmatter___date, order:DESC},
            filter: {fileAbsolutePath: {regex: "/(information)/.*.md$/"}}
          ) {
            edges {
                node {
                    frontmatter {
                        title
                        date(formatString: "YYYY年MM月DD日")
                    }
                    fields {
                        slug
                    }
                    html
                }
            }
        }
    }
`